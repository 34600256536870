export const parsePixoAppURI = (uri: string) => {
  const [scheme, body] = uri.split('://');

  if (scheme !== 'pixo-app') throw new Error('This is not pixo-app URI');

  const [
    identity,
    type,
    id,
  ] = body.split('/');

  return {
    identity,
    type,
    id,
  };
};

export const parsePixoCmsURI = (uri: string) => {
  if (uri.startsWith('pixo-cms://') === false) throw new Error('It is not valid type(pixo-cms url)');

  const [scheme, body] = uri.split('://');
  const [identity, type, name] = body.split('/');

  return {
    scheme,
    body,
    identity,
    type,
    name,
  };
};
