import { AxiosRequestConfig } from 'axios';

import { LGSTemplate, LGSTemplateCategory, LGSTemplateCategoryList, LGSTemplateList } from '~/src/types/lgs-template';

import { LGSResource, LGSResourceList } from '../../types/lgs-resource';
import { DetailedMyWorkResponse } from '../../types/my-work';
import { APIClient } from '../APIClient';
import { GetResourceByIdParams, GetResourceListByTemplateIdParams, GetTemplateCategoryListParams, GetTemplateCategoryParams, GetTemplateListByCategoryIdsParams, GetTemplateListParams, GetTemplateParmas } from './TemplatesAPI.types';

export class TemplatesAPI extends APIClient {
  // 응답에 공통으로 포함될 커스텀 해더값
  customHeader = {
    'X-Accept-Template-Version': process.env.NEXT_PUBLIC_JSON_SCHEMA_VERSION,
  };

  getTemplate(templateId: string, params?: Partial<GetTemplateParmas>) {
    return this.axios.get<LGSTemplate>(`/${this.appName}/templates/${templateId}`, {
      headers: this.customHeader,
      params,
    });
  }

  getCategory(categoryId: string, config?: AxiosRequestConfig<GetTemplateCategoryParams>) {
    return this.axios.get<LGSTemplateCategory>(`/${this.appName}/templates/categories/${categoryId}`, config);
  }

  getCategoryList(params?: Partial<GetTemplateCategoryListParams>) {
    return this.axios.get<LGSTemplateCategoryList>(`/${this.appName}/templates/categories/list`, {
      headers: this.customHeader,
      params,
    });
  }

  getTemplateListByCategoryId(categoryId: string, params?: Partial<GetTemplateListParams>) {
    return this.axios.get<LGSTemplateList>(`/${this.appName}/templates/categories/${categoryId}/templates/list`, {
      headers: this.customHeader,
      params,
    });
  }

  getTemplateListByCategoryIds(params?: Partial<GetTemplateListByCategoryIdsParams>) {
    return this.axios.get<LGSTemplateList>(`/${this.appName}/templates/categories/templates/list`, {
      headers: this.customHeader,
      params,
    });
  }

  getResourceListByTemplateId(templateId: string, params?: Partial<GetResourceListByTemplateIdParams>) {
    return this.axios.get<LGSResourceList>(`/${this.appName}/templates/${templateId}/resources/list`, {
      headers: this.customHeader,
      params,
    });
  }

  getResourceById(resourceId: string, params?: Partial<GetResourceByIdParams>) {
    return this.axios.get<LGSResource>(`/${this.appName}/resources/${resourceId}`, {
      headers: this.customHeader,
      params });
  }

  getMyWorkFormat(templateId: string) {
    return this.axios.get<DetailedMyWorkResponse>(
      `${this.appName}/myWorks/templates/${templateId}`
    ).then((r) => r.data);
  }
}

export const templateAPIClient = new TemplatesAPI;

export default templateAPIClient;
