import { Template, TemplateObject } from '@lws/types';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { myWorksAPIClient } from '~/src/api/MyWorksAPI';
import { MyWork, TemplateResourceGroup, TemplateResourceMetaGroup } from '~/src/types/my-work';

export interface InitializeMyWorkThunkResponse {
  myWork: MyWork;
  template: Template;
  objects: TemplateObject[];
  resources: TemplateResourceGroup;
  meta: TemplateResourceMetaGroup;
}

export const updateMyWorkTitleThunk = createAsyncThunk(
  'myWork/updateMyWorkTitleThunk',
  (payload: { userId: string, myWorkId: string, title: string }) => {
    return new Promise<{ title: string }>((resolve, reject) => {
      if (payload.title === undefined) return reject('Please enter the title to edit');
      if (payload.myWorkId === undefined) return reject('Please enter the mywork id');
      if (payload.userId === undefined) return reject('Please enter the uesr account ID');

      myWorksAPIClient
        .updateItemMetadata(
          payload.userId,
          payload.myWorkId,
          { title: payload.title }
        )
        .then(() => {
          return resolve({ title: payload.title });
        })
        .catch(() => {
          return reject('Unknown Error');
        });
    });
  }
);
