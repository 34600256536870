import { createTheme, ThemeProvider } from '@mui/material';
import { ReactNode } from 'react';

import { DEFAULT_THEME, DefaultTheme, variants } from '~/src/themes/default';

export interface GlobalThemeProviderProps {
  children?: ReactNode;
  theme?: DEFAULT_THEME;
}

/**
 * PD 담당자가 중간에 바뀌면서 새로운 디자인 시스템을 구성함
 * 새로운 디자인 시스템으로 한 번에 바꾸지 않고 점진적으로 바꿀 계획임
 * 그로 인해, 현재 Theme Provider가 2개가 필요한 상황임 (레거시 디자인 시스템, 새로운 디자인 시스템)
 * 레거시 디자인 시스템을 위한 Provider : LegacyGlobalThemeProvider
 * 새로운 디자인 시스템을 위한 Provider : GlobalThemeProvider
 */
export const GlobalThemeProvider = (props: GlobalThemeProviderProps) => {
  const appTheme = props.theme ?? DefaultTheme;

  const theme = createTheme({
    app: appTheme,
    breakpoints: {
      values: DefaultTheme.breakpoints,
    },
    typography: {
      fontFamily: appTheme.typography['basic-font'],
      ...variants,
    },
    components: {
      MuiTypography: {
        defaultProps: {
          fontFamily: [
            appTheme.typography['basic-font'],
            '-apple-system',
            'sans-serif',
          ].join(', '),
          variantMapping: {
            h1: 'h1',
            h2: 'h2',
            h3: 'h3',
            h4: 'h4',
            h5: 'h5',
            h7: 'h6',
            body1: 'p',
            body2: 'p',
            body3: 'p',
            body4: 'p',
            body5: 'p',
            body6: 'p',
            body7: 'p',
            body8: 'p',
            body9: 'p',
            body10: 'p',
            body11: 'p',
            button1: 'span',
            button2: 'span',
            button3: 'span',
            button4: 'span',
            button5: 'span',
            p: 'p',
            sm: 'small',
            xs: 'small',
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          arrow: {
            color: appTheme.colors['inverse-background-background-inverse-secondary'],
          },
          tooltip: {
            borderRadius: '8px',
            backgroundColor: appTheme.colors['inverse-background-background-inverse-secondary'],
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      { props.children }
    </ThemeProvider>
  );
};
