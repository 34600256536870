import 'normalize.css';
import '@/assets/styles/global.scss';
import 'rc-slider/assets/index.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-quill/dist/quill.snow.css';

import { ParticleProvider } from '@lws/common';
import { DehydratedState, Hydrate } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { appWithTranslation } from 'next-i18next';
import { Provider } from 'react-redux';

import { wrapper } from '@/store/index';
import { LegacyGlobalThemeProvider } from '~/src/components/app/GlobalThemeProvider';
import { AppHead } from '~/src/components/business/AppHead';
import { ExternalScript } from '~/src/components/business/ExternalScript';
import { QueryClientProvider } from '~/src/context/QueryClientContext';

import { LottieLoadingOverlayProvider } from '../components/app/LottieLoadingOverlayProvider/LottieLoadingProvider';
import { MobileLimitAlert } from '../components/app/MobileLimitAlert';
import { RoutingProgress } from '../components/app/RoutingProgress';
import { AmplitudeSDK } from '../components/business/AmplitudeSDK';
import { AppErrorBoundary } from '../components/business/AppErrorBoundary';
import { AccountDialogProvider } from '../components/business/Dialogs';
import { RebrandModalProvider } from '../components/business/RebrandModal';
import { RouteActionProvider } from '../components/business/RouteActionProvider';
import { Smartlook } from '../components/business/Smartlook';
import { Toastify } from '../components/business/Toastify';
import { AppPropsWithLayout } from '../types/common';

export interface AppProps {
  idToken?: string;
  cookies?: { [key: string]: string };
  dehydratedState: DehydratedState;
}

// SmartLook 전역 객체에 대한 인터페이스 정의
declare global {
  interface Window {
    smartlook: any;
  }
}

export const App = ({ Component, ...rest }: AppPropsWithLayout<AppProps>) => {
  const Layout = Component.Layout || ((page) => page);
  const { store, props } = wrapper.useWrappedStore(rest);

  return (
    <QueryClientProvider>
      <Provider store={store}>
        <AppHead />
        {/* Fix: 원래는 로그인 로직을 SSR에서 초기화 하기 위해 넣었는데 지금은 유명무실한듯? */}
        <Hydrate
          state={rest.pageProps.dehydratedState}
        >
          <Smartlook />
          <AmplitudeSDK />
          <AppErrorBoundary>
            <ReactQueryDevtools />
            <LegacyGlobalThemeProvider>
              <AccountDialogProvider>
                <ParticleProvider>
                  <LottieLoadingOverlayProvider>
                    <RouteActionProvider>
                      {/* MEMO: 추후 리브랜딩 Modal의 수요가 없어진다 판단되면 해당 Provider를 반드시 지울 것. */}
                      <RebrandModalProvider>
                        { Layout(<Component { ...props.pageProps } {...props} />) }
                        <Toastify />
                        <RoutingProgress />

                        { /* Mobile일 때 항상 표시되는 알러트 */}
                        <MobileLimitAlert />
                      </RebrandModalProvider>
                    </RouteActionProvider>
                  </LottieLoadingOverlayProvider>
                </ParticleProvider>
              </AccountDialogProvider>
            </LegacyGlobalThemeProvider>
          </AppErrorBoundary>
        </Hydrate>
        <ExternalScript />
      </Provider>
    </QueryClientProvider>
  );
};

export default appWithTranslation(App);
