import { css, styled, Typography } from '@mui/material';
import classNames from 'classnames';
import { Trans, useTranslation } from 'next-i18next';
import { Fragment, useCallback, useState } from 'react';

import { Dialog } from '~/src/components/app/Dialog';
import { LoadingOverlay } from '~/src/components/app/LoadingOverlay';
import { Nl2br } from '~/src/components/app/Nl2br/Nlbr';
import { SocialButton } from '~/src/components/business/SocialButton/SocialButton';
import { AUTH_PROVIDER_APPLE, AUTH_PROVIDER_FACEBOOK, AUTH_PROVIDER_GOOGLE } from '~/src/constants/Auth';
import { AmplitudeTaxonomyClient } from '~/src/helpers/AmplitudeTaxonomyClient';
import { AuthProviderName } from '~/src/types/common';
import { CognitoClient } from '~/src/utils/cognito';

import { Logo } from '../../Logo';
import { AccountDialogProps } from './AccountDialog.types';
import Styles from './index.module.scss';

export const AccountDialog = ({
  show,
  onClose,
}: AccountDialogProps) => {
  const { t } = useTranslation('common');

  const [isShowLoading, setIsShowLoading] = useState(false);

  const startSignInByProvider = useCallback((provider: AuthProviderName) => {
    switch (provider) {
    case AUTH_PROVIDER_GOOGLE:
      AmplitudeTaxonomyClient.clickSocialIcon({
        social: 'google',
      });
      break;

    case AUTH_PROVIDER_FACEBOOK:
      AmplitudeTaxonomyClient.clickSocialIcon({
        social: 'facebook',
      });
      break;

    case AUTH_PROVIDER_APPLE:
      AmplitudeTaxonomyClient.clickSocialIcon({
        social: 'apple',
      });
      break;
    }

    setIsShowLoading(true);
    CognitoClient.signIn(provider);
  }, []);

  const signInGoogle = useCallback(() => {
    startSignInByProvider(AUTH_PROVIDER_GOOGLE);
  }, [
    startSignInByProvider,
  ]);

  const signInFacebook = useCallback(() => {
    startSignInByProvider(AUTH_PROVIDER_FACEBOOK);
  }, [
    startSignInByProvider,
  ]);

  const signInApple = useCallback(() => {
    startSignInByProvider(AUTH_PROVIDER_APPLE);
  }, [
    startSignInByProvider,
  ]);

  return (
    <Fragment>
      <Dialog
        className={classNames(
          'AccountDialog'
        )}
        open={show}
        onClose={onClose}
      >
        <div
          css={css`
            padding: 0 48px 60px;
            max-width: 400px;
          `}
        >
          <div
            className={'AccountModal__LogoContainer'}
            css={css`
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;
          `}
          >
            <Logo
              css={css`
                width: 108px;
                height: 20px;
              `}
            />
          </div>
          <div
            className={'AccountModal__Information'}
            css={css`
            text-align: center;
          `}
          >
            <Typography
              variant="h4"
              css={css`
              color: '#000';
            `}
            >
              { t('common:login-modal.title') }
            </Typography>
            <Typography
              variant="body6"
              css={css`
                margin-top: 10px;
                color: #555;
              `}
            >
              <Nl2br>
                { t('common:login-modal.description') }
              </Nl2br>
            </Typography>
          </div>
          <div
            css={css`
            margin-top: 28px;
            margin-bottom: 28px;
          `}
          >
            <ButtonContainer>
              <SocialButtonContainer
                className={Styles.Social}
                variant={AUTH_PROVIDER_GOOGLE}
                onClick={signInGoogle}
              />
            </ButtonContainer>
            <ButtonContainer className={Styles.Button}>
              <SocialButtonContainer
                className={Styles.Social}
                variant={AUTH_PROVIDER_FACEBOOK}
                onClick={signInFacebook}
              />
            </ButtonContainer>
            <ButtonContainer className={Styles.Button}>
              <SocialButtonContainer
                className={Styles.Social}
                variant={AUTH_PROVIDER_APPLE}
                onClick={signInApple}
              />
            </ButtonContainer>
          </div>
          <div
            css={css`
            text-align: center;
          `}
          >
            <Typography
              variant="body9"
              css={css`
                color: #919191;
              `}
            >
              <Trans
                name="common"
                i18nKey={'login-modal.policy'}
                components={{
                  terms: <a href="/documents/service" target="_blank" />,
                  privacy: <a href="/documents/privacy" target="_blank" />,
                }}
              />
            </Typography>
          </div>
        </div>
      </Dialog>

      <LoadingOverlay active={isShowLoading} />
    </Fragment>
  );
};

const ButtonContainer = styled('div')`
  width: 100%;
  margin-bottom: 10px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const SocialButtonContainer = styled(SocialButton)`
  width: 100%;
`;
