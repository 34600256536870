import { customParams, myWorkId, payRequestParams, productInfo, userInfo, userName } from './index.types';

// Full Name을 입력받아 First Name과 Last Name을 분리하여 리턴한다.
export const getSeperatedUserName = (fullName : string) : userName => {
  if(!fullName) throw new Error('There is no user name');

  const [firstName, lastName] = fullName.split(' ');

  return {
    firstName : firstName || '',
    lastName : lastName || '',
  };
};

// 결제 모듈 호출에 필요한 파라메터를 리턴한다.
export const getPayRequestParams = (authToken : string, productInfo: productInfo, userInfo: userInfo, myWorkId: myWorkId = null) : payRequestParams => {
  const { name, email, userId } = userInfo;
  const { planId, planName, price, currency } = productInfo;

  // name이 없는 경우 항상 빈값 (사용자가 결제 모듈에서 입력하도록)
  let firstName = '';
  let lastName = '';

  // userData에 name이 있는 경우에 구분해서 대입
  if(name) {
    const { firstName : seperactedFirstName, lastName : seperatedLastName } = getSeperatedUserName(name);

    firstName = seperactedFirstName;
    lastName = seperatedLastName;
  }

  const params = {
    Authorization : authToken,
    service_id : process.env.NEXT_PUBLIC_PAYPLE_SERVICE_ID || 'PIXO',
    comments : planName,
    totalAmount : price,
    currency : currency?.toUpperCase(),
    firstName,
    lastName,
    email,
    isDirect : '',
    serviceDefine: '',
    payCls : 'demo',
  };

  // serviceDefine에 담을 커스텀 파라메터
  const customParams : customParams = {
    userId : userId,
    planId: planId,
    failedRedirectUrl : `${process.env.NEXT_PUBLIC_DOMAIN}/pricing`,
    redirectUrl: `${process.env.NEXT_PUBLIC_DOMAIN}/templates?paymentSuccess=true`,
  };

  // myWorkId를 가지고 있으면(에디터에서 진입 한 케이스)
  // 결제 실패시 기존 에디터 페이지로 이동 시킨다.
  // 결제 성공시 추가 Flag를 주어 후속 기능을 동작하게 한다.
  if(myWorkId) {
    customParams.myWorkId = myWorkId;
    customParams.failedRedirectUrl = `${process.env.NEXT_PUBLIC_DOMAIN}/editor?myWorkId=${myWorkId}&`;
    customParams.redirectUrl = `${process.env.NEXT_PUBLIC_DOMAIN}/editor?myWorkId=${myWorkId}&planId=${planId}&paymentSuccess=true`;
  }

  // serviceDefine 파라메터 암호화
  const stringifiedCustomParams = JSON.stringify(customParams);
  params.serviceDefine = btoa(stringifiedCustomParams);

  // 운영환경에서는 payCLS 파라메터 제거
  if(process.env.NEXT_PUBLIC_PAYPLE_SERVICE_ID !== 'demo') {
    delete (params as any).payCls;
  }

  return params;
};
