

import { createAsyncThunk, createSelector, createSlice, PayloadAction, Selector, SliceCaseReducers } from '@reduxjs/toolkit';

import { policyAPIClient } from '~/src/api/PolicyAPI';
import { LGSHomePolicy, LGSHomePolicyStringSlideItem } from '~/src/types/home-policy';
import { URIParser } from '~/src/utils/uri-parser';

import { APP_STATE, hydrate } from '../types';

export const homePolicyName = 'HomePolicy';

export interface LWSStringSlideItem extends LGSHomePolicyStringSlideItem {
  id: string;
}

const initialState = {
  policy: null as null | LGSHomePolicy,
};

export type HOME_POLICY_STATE = typeof initialState;

export interface HomePolicyReducer extends SliceCaseReducers<HOME_POLICY_STATE> {
  setHomePolicy: (state: HOME_POLICY_STATE, action: PayloadAction<LGSHomePolicy>) => void;
}

export const fetchHomePolicyAsync = createAsyncThunk(
  `${homePolicyName}/fetchHomePolicy`,
  async (_, thunkAPI) => {
    try {
      const policy = (await policyAPIClient.getHome()).data;
      const stringSlides = (policy.sections.find((section) => section.type === 'stringSlide'));
      const items = ((stringSlides?.items ?? []) as LGSHomePolicyStringSlideItem[]).map((item) => {
        const id = URIParser.getCategoryId(item.value);

        return {
          ...item,
          id,
        };
      });

      if (stringSlides) stringSlides.items = items;

      return policy;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const homePolicySlice = createSlice<HOME_POLICY_STATE, HomePolicyReducer>({
  name: homePolicyName,
  initialState,
  reducers: {
    setHomePolicy: (state, action) => {
      state.policy = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(hydrate, (state, action) => {
        return {
          ...state,
          ...action.payload.homePolicy,
        };
      })
      .addCase(fetchHomePolicyAsync.fulfilled, (state, action) => {
        state.policy = action.payload;
      });
  },
});

const getAppState = (state: APP_STATE) => state.homePolicy;

export const stringSlideSectionSelector: Selector<APP_STATE, LWSStringSlideItem[] | undefined> = createSelector(
  getAppState,
  (state) => {
    const result = state.policy?.sections.find((section) => section.type === 'stringSlide');
    return (result?.items ?? []) as LWSStringSlideItem[];
  }
);
export const imageSlideSectionSelector = createSelector(getAppState, (state) => state.policy?.sections.find((section) => section.type === 'imageSlide'));
export const buttonSlideSectionSelector = createSelector(getAppState, (state) => state.policy?.sections.find((section) => section.type === 'buttonSlide'));

export const { setHomePolicy } = homePolicySlice.actions;

export const homePolicyReducer = homePolicySlice.reducer;

export default homePolicySlice;
