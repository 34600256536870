import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { GetMyPlanResponse, paymentsAPIClient } from '~/src/api/PaymentsAPI';
import { USER_PLAN_NOT_USER } from '~/src/constants/UserPlan';
import { UserPlan, UserPlanNotUser } from '~/src/types/user';

import { useAuth } from './useAuth';

/**
 * 사용자의 플랜 정보를 불러오고 반환하는 hook입니다.
 */
export const usePlan = () => {
  const {
    userId,
    isSuccess,
    isInitialLoading,
  } = useAuth();

  const userPlanQuery = useQuery({
    queryKey: ['user', 'plan', userId],
    queryFn: () => {
      return new Promise<GetMyPlanResponse | UserPlanNotUser | undefined>((resolve) => {
        if (isSuccess === false && isInitialLoading === false) return resolve(undefined);
        if (userId === undefined) return resolve(USER_PLAN_NOT_USER);

        return paymentsAPIClient.getMyPlan(userId).then((result) => resolve(result));
      });
    },
    enabled: Boolean(userId),
  });

  const userPlan = useMemo<UserPlan | UserPlanNotUser | undefined>(() => {
    if (userPlanQuery.isFetching && userPlanQuery.data === undefined) return undefined;
    if (userPlanQuery.data === USER_PLAN_NOT_USER) return USER_PLAN_NOT_USER;

    return userPlanQuery.data?.plan;
  }, [userPlanQuery]);

  const planExpirationDate = useMemo(() => {
    if (typeof userPlanQuery.data !== 'object') return undefined;
    if ('expirationDate' in userPlanQuery.data) return userPlanQuery.data.expirationDate;

    return undefined;
  }, [userPlanQuery]);

  const planSubscriptionId = useMemo(() => {
    if (typeof userPlanQuery.data !== 'object') return undefined;
    if ('subscriptionId' in userPlanQuery.data) return userPlanQuery.data.subscriptionId;

    return undefined;
  }, [userPlanQuery]);

  return {
    userPlanQuery,
    userPlan,
    planExpirationDate,
    planSubscriptionId,
  };
};
