import { TemplateTextObject } from '@lws/types';

import { CURVED_TEXT_DIRECTION_DOWN, CURVED_TEXT_DIRECTION_NO, CURVED_TEXT_DIRECTION_UP } from '../../constants';
import { CURVED_TEXT_ALL_DIRECTION, CURVED_TEXT_VALID_DIRECTION } from '../../constants/common/types';
import { convertDegToRad, convertRadToDeg, getCenterPoint, getCurvedTextAngle, getFontResourceFromDecorators, getSingleLineText, getTextObjectFontSize, getTextObjectLineSpacingCSS, getTextObjectSize, renderPlainText } from '../../utils';
import { PlainTextManager } from '../PlainTextManager';
import { CurvedTextElements, GetCurvedTextAngleArguments, GetCurvedTextArcLengthArguments, GetCurvedTextCircleCenterPointArguments, GetCurvedTextStartAngleArguments, GetCurvedTextStartPointArguments } from './CurvedTextManager.types';

export class CurvedTextManager {
  static getInitialCurvedText(object: TemplateTextObject) {
    let step = object.step || 0;
    let radius = object.radius;
    let arcLength = object.curvedTextExtraParameters?.arcLength ?? 0;

    if (step === 0 || arcLength === 0) {
      if (step === 0) {
        const size = this.getTextWithoutKernSize(object);
        step = this.getCurvedTextStep(size?.width ?? 0, size?.height ?? 0);
        arcLength = size?.width ?? 0;
        radius = this.getRadius(object.curve, step, arcLength);
      } else {
        arcLength = this.getArcLength(step, object.curve, radius);
      }
    }

    return {
      ...object,
      step,
      radius,
      curvedTextExtraParameters: {
        arcLength,
      },
    } as TemplateTextObject;
  }

  static getCurvedTextUniqueKey(primaryKey: string) {
    return `Curved-Text-${primaryKey}`;
  }

  static getArcPath(
    degree: number,
    radius: number,
    curveDirection: CURVED_TEXT_ALL_DIRECTION
  ) {

    const optimizedDegree = degree % 360;

    if (curveDirection === CURVED_TEXT_DIRECTION_DOWN) {
      if (0 <= optimizedDegree && optimizedDegree <= 89) {
        return `a${radius},${radius} 0 0,0 ${radius},${-radius}`;
      }
      if (90 <= optimizedDegree && optimizedDegree <= 179) {
        return `a${radius},${radius} 0 0,0 ${-radius},${-radius}`;
      }
      if (180 <= optimizedDegree && optimizedDegree <= 269) {
        return `a${radius},${radius} 0 0,0 ${-radius},${radius}`;
      }
      if (270 <= optimizedDegree && optimizedDegree <= 360) {
        return `a${radius},${radius} 0 0,0 ${radius},${radius}`;
      }
    } else {
      if (0 <= optimizedDegree && optimizedDegree <= 89) {
        return `a${radius},${radius} 0 0,1 ${radius},${radius}`;
      }
      if (90 <= optimizedDegree && optimizedDegree <= 179) {
        return `a${radius},${radius} 0 0,1 ${-radius},${radius}`;
      }
      if (180 <= optimizedDegree && optimizedDegree <= 269) {
        return `a${radius},${radius} 0 0,1 ${-radius},${-radius}`;
      }
      if (270 <= optimizedDegree && optimizedDegree <= 360) {
        return `a${radius},${radius} 0 0,1 ${radius},${-radius}`;
      }
    }
  }

  static getArcPaths(
    startPointX: number,
    startPointY: number,
    degree: number,
    radius: number,
    curveDirection: CURVED_TEXT_ALL_DIRECTION
  ) {
    const startPath = `M${startPointX},${startPointY}`;
    let paths = '';

    if (degree === Infinity) return '';

    for (let d = 0; d < degree; d += 90) {
      paths = `${paths} ${this.getArcPath(d, radius, curveDirection)}`;
    }

    return `${startPath} ${paths}`;
  }

  static getInlineText(string: string) {
    return string.replaceAll('\\n', '\n').replaceAll('\n', ' ');
  }

  static getTextSize(object: TemplateTextObject) {
    const {
      text,
      decoratorIds,
      scale,
      kerning,
    } = object;

    const fontFamily = getFontResourceFromDecorators(decoratorIds);
    const fontSize = getTextObjectFontSize(scale);
    const letterSpacing = PlainTextManager.getLetterSpacingStyleFromKerning(kerning, scale);
    const inlineText = this.getInlineText(text);

    if (fontFamily) {
      const { width, height } = getTextObjectSize({
        text: inlineText,
        fontFamily,
        fontSize,
        letterSpacing,
      });

      return {
        width,
        height,
      };
    }

    return undefined;
  }

  static getTextWithoutKernSize(object: TemplateTextObject) {
    const {
      text,
      decoratorIds,
      scale,
    } = object;

    const fontFamily = getFontResourceFromDecorators(decoratorIds);
    const fontSize = getTextObjectFontSize(scale);
    const inlineText = this.getInlineText(text);

    if (fontFamily) {
      const { width, height } = getTextObjectSize({
        text: inlineText,
        fontFamily,
        fontSize,
      });

      return {
        width,
        height,
      };
    }

    return undefined;
  }

  static getRadius(curve: number, step: number, arcLength: number) {
    if (curve === 0) return 0;
    if (step === 0) return 0;
    const absCurve = Math.abs(curve);

    return arcLength / (Math.PI * absCurve * step) * 180;
  }

  static getArcLength(step: number, curve: number, radius: number) {
    const arcLength = (Math.abs(curve) * step * Math.PI * radius) / 180.0;

    return arcLength;
  }

  static getRadiusOfArcLengthWithDegree(arcLength: number, degree: number) {
    return (arcLength / (degree * Math.PI)) * 180;
  }

  static getCurvedTextFrameSize(radius: number, angle: number, singleLineHeight: number) {
    const width = this.getChord(radius, Math.min(180, angle), true);
    const height = this.getSagitta(radius, Math.min(360, angle), true) + singleLineHeight;

    return {
      width,
      height,
    };
  }

  static getFrameSizeFromObject(object: TemplateTextObject) {
    let frameWidth = 0;
    let frameHeight = 0;

    const {
      curve,
      radius,
      parameters,
      kerning,
      scale,
      text,
      lineSpacing,
      decoratorIds,
    } = object;

    const size = this.getTextSize(object);
    const arcLength = size?.width ?? 0;
    const {
      singleLineHeight,
    } = parameters;

    const angle = getCurvedTextAngle({
      radius,
      arcLength,
    });
    const lineSpacingStyle = getTextObjectLineSpacingCSS({
      singleLineHeight,
      lineSpacing,
    });

    // curve가 0이 아니라면 반환하게 될 CurvedText의 사이즈
    if (curve !== 0) {
      const {
        width: textAreaWidth,
        height: textAreaHeight,
      } = this.getCurvedTextFrameSize(
        radius,
        angle,
        singleLineHeight
      );

      frameWidth = textAreaWidth;
      frameHeight = textAreaHeight;
    } else {
      const plainTextLetterSpacing = PlainTextManager.getLetterSpacingStyleFromKerning(kerning, scale);
      const fontFamily = getFontResourceFromDecorators(decoratorIds);
      const fontSize = getTextObjectFontSize(scale);

      if (fontFamily) {
        const {
          width: plainTextWidth,
          height: plainTextHeight,
        } = getTextObjectSize({
          text,
          letterSpacing: plainTextLetterSpacing,
          lineSpacing: lineSpacingStyle,
          fontFamily,
          fontSize,
        });

        frameWidth = plainTextWidth;
        frameHeight = plainTextHeight;
      }
    }

    return {
      frameWidth,
      frameHeight,
    };
  }

  static getCurvedTextStep(width: number, height: number) {
    const ratio = width / height;
    const step = (ratio / 6.0) * (360.0 / 100.0);

    return step;
  }

  static getCurvedTextWrapperElement(primaryKey: string) {
    const element = document.querySelector(`svg[data-curved-text-key="Curved-Text-${primaryKey}"]`) as SVGSVGElement;

    return element;
  }

  static getChord(radius: number, angle: number, isDegree = false) {
    const radian = isDegree ? convertDegToRad(angle / 2) : angle / 2;
    return 2 * radius * Math.sin(radian);
  }

  static getSagitta(radius: number, angle: number, isDegree = false) {
    const radian = isDegree ? convertDegToRad(angle / 2) : (angle / 2);
    return radius * (1 - Math.cos(radian));
  }

  static getCurvedTextStartAngle = ({
    curveDirection,
    angle,
    isDegree = true,
  }: GetCurvedTextStartAngleArguments) => {
    const degree = isDegree ? angle : convertRadToDeg(angle);

    return (curveDirection === CURVED_TEXT_DIRECTION_UP ? degree : -degree) / 2;
  };

  static getCurvedTextElements(object: TemplateTextObject) {
    const { parameters } = object;
    const { primaryKey } = parameters;
    const svgElement = this.getCurvedTextWrapperElement(primaryKey);
    const curvedTextKey = svgElement.getAttribute('data-curved-text-key');

    if (curvedTextKey === null) return undefined;

    const key = this.getCurvedTextUniqueKey(primaryKey);

    const pathElement = svgElement.querySelector(`path[data-curved-text-path-key="${key}"]`) as SVGPathElement;
    const textElement = svgElement.querySelector(`text[data-curved-text-text-key="${key}"]`) as SVGTextElement;
    const textPathElement = svgElement.querySelector(`textPath[data-curved-text-textpath-key="${key}"]`) as SVGTextPathElement;

    if (
      !svgElement ||
      !pathElement ||
      !textElement ||
      !textPathElement
    ) return undefined;

    return {
      svgElement,
      pathElement,
      textElement,
      textPathElement,
    };
  }

  static getCurvedTextStartPoint({
    curveDirection,
    textAreaCenterX,
    textAreaCenterY,
    textAreaHeight,
    singleLineHeight,
  }: GetCurvedTextStartPointArguments) {
    const y = curveDirection === CURVED_TEXT_DIRECTION_UP ?
      textAreaCenterY - (textAreaHeight - singleLineHeight) / 2 :
      textAreaCenterY + (textAreaHeight - singleLineHeight) / 2;

    return { x: textAreaCenterX, y };
  }

  static getCurvedTextCircleCenterPoint({
    startPointX,
    startPointY,
    curveDirection,
    radius,
  }: GetCurvedTextCircleCenterPointArguments) {
    return curveDirection === CURVED_TEXT_DIRECTION_UP ?
      { x: startPointX, y: startPointY + radius } :
      { x: startPointX, y: startPointY - radius };
  }

  static getCurvedTextDirection(curve: number) {
    return curve > 0 ? CURVED_TEXT_DIRECTION_UP : curve < 0 ? CURVED_TEXT_DIRECTION_DOWN : CURVED_TEXT_DIRECTION_NO;
  }

  static getCurvedTextArcLength = ({
    text,
    fontFamily,
    fontSize,
    letterSpacing,
  }: GetCurvedTextArcLengthArguments) => {
    const plainText = getSingleLineText(text);

    const node = renderPlainText({
      text: plainText,
      fontFamily,
      fontSize,
      letterSpacing,
    });

    const { width } = node.getBoundingClientRect();

    node.remove();

    return width;
  };

  static getCurvedTextAngle = ({
    arcLength,
    radius,
  }: GetCurvedTextAngleArguments) => {
    if (radius === 0 || !radius) return 0;

    return (arcLength / (radius * Math.PI)) * 180;
  };

  static getCurvedTextStyles(object: TemplateTextObject) {
    const {
      curve,
      radius,
      text,
      decoratorIds,
      parameters,
      scale,
      kerning,
      hexColor,
      alpha,
    } = object;

    const {
      primaryKey,
      singleLineHeight,
    } = parameters;

    const key = this.getCurvedTextUniqueKey(primaryKey);
    const fontFamily = getFontResourceFromDecorators(decoratorIds);

    if (fontFamily === undefined) throw new Error('Cannot found font.');

    const fontSize = getTextObjectFontSize(scale);
    const curveDirection = this.getCurvedTextDirection(curve) as CURVED_TEXT_VALID_DIRECTION;

    const size = this.getTextSize(object);
    const arcLength = size?.width ?? 0;
    const plainTextLetterSpacing = PlainTextManager.getLetterSpacingStyleFromKerning(kerning, scale);

    const angle = this.getCurvedTextAngle({
      radius,
      arcLength: arcLength - (plainTextLetterSpacing * scale),
    });

    const {
      frameWidth,
      frameHeight,
    } = this.getFrameSizeFromObject(object);

    const {
      x: textAreaCenterX,
      y: textAreaCenterY,
    } = getCenterPoint(frameWidth, frameHeight);

    const {
      x: startPointX,
      y: startPointY,
    } = this.getCurvedTextStartPoint({
      textAreaCenterX,
      textAreaCenterY,
      textAreaHeight: frameHeight,
      curveDirection,
      singleLineHeight,
    });

    const path = this.getArcPaths(
      startPointX,
      startPointY,
      angle,
      radius,
      curveDirection
    );

    const startAngle = this.getCurvedTextStartAngle({
      curveDirection,
      angle,
    });

    const {
      x: circleCenterX,
      y: circleCenterY,
    } = this.getCurvedTextCircleCenterPoint({
      startPointX: startPointX,
      startPointY: startPointY,
      radius,
      curveDirection,
    });

    return {
      key,
      alpha,
      frameWidth,
      frameHeight,
      startPointX,
      startPointY,
      startAngle,
      scale,
      radius,
      curve,
      path,
      circleCenterX,
      circleCenterY,
      fontSize,
      fontFamily,
      hexColor,
      text,
      letterSpacing: plainTextLetterSpacing,
      curvedTextLetterSpacing: plainTextLetterSpacing * scale,
    };
  }

  static setCurvedTextElementsStyle(
    object: TemplateTextObject,
    elements?: CurvedTextElements
  ) {
    const {
      alpha,
      frameWidth,
      frameHeight,
      path,
      fontSize,
      fontFamily,
      startAngle,
      circleCenterX,
      circleCenterY,
      curvedTextLetterSpacing,
      letterSpacing,
      text,
      hexColor,
    } = this.getCurvedTextStyles(object);

    let svgElement: SVGSVGElement | undefined = undefined;
    let pathElement: SVGPathElement | undefined = undefined;
    let textElement: SVGTextElement | undefined = undefined;
    let textPathElement: SVGTextPathElement | undefined = undefined;

    if (elements) {
      svgElement = elements.svgElement;
      pathElement = elements.pathElement;
      textElement = elements.textElement;
      textPathElement = elements.textPathElement;
    } else {
      const curvedTextElements = this.getCurvedTextElements(object);

      if (curvedTextElements) {
        svgElement = curvedTextElements.svgElement;
        pathElement = curvedTextElements.pathElement;
        textElement = curvedTextElements.textElement;
        textPathElement = curvedTextElements.textPathElement;
      }
    }


    if (svgElement && pathElement && textElement && textPathElement) {
      const convertedText = text.replaceAll('\\n', '\n').replaceAll('\n', ' ');

      svgElement.setAttribute('width', frameWidth.toString());
      svgElement.setAttribute('height', frameHeight.toString());
      svgElement.setAttribute('viewBox', `0 0 ${frameWidth} ${frameHeight}`);
      svgElement.style.setProperty('--alpha', alpha.toString());
      svgElement.style.setProperty('--color', hexColor);

      pathElement.setAttribute('d', path);

      textElement.setAttribute('font-size', fontSize.toString());
      textElement.setAttribute('font-family', fontFamily);
      textElement.setAttribute('letter-spacing', `${curvedTextLetterSpacing}`);
      textElement.style.setProperty('fill', 'var(--color)');
      textElement.style.setProperty('transform', `rotate(${startAngle * -1}deg)`);
      textElement.style.setProperty('transform-origin', `${circleCenterX}px ${circleCenterY}px`);
      textElement.style.setProperty('opacity', 'var(--alpha)');
      // textElement.style.setProperty('margin-right', `-${curvedTextLetterSpacing}px`);

      textPathElement.textContent = convertedText;
    }

    return {
      frameWidth,
      frameHeight,
      path,
      fontSize,
      fontFamily,
      letterSpacing,
      startAngle,
      circleCenterX,
      circleCenterY,
      text,
    };
  }
}
