import * as amplitude from '@amplitude/analytics-browser';
import { useEffect } from 'react';

export const AmplitudeSDK = () => {
  useEffect(() => {
    amplitude.init(process.env.NEXT_PUBLIC_AMPLITUDE_TAXONOMY_API_KEY as string);
  }, []);

  return (
    <></>
  );
};
