/** 이벤트 이름 */
export const TAXONOMY_EVENT_TYPE_CLICK_ACCOUNT_SETTING = 'click_account_setting';
export const TAXONOMY_EVENT_TYPE_CLICK_LOGIN_BUTTON = 'click_login';
export const TAXONOMY_EVENT_TYPE_CLICK_MASTER_BADGE = 'click_master_badge';
export const TAXONOMY_EVENT_TYPE_CLICK_MYWORK = 'click_mywork';
export const TAXONOMY_EVENT_TYPE_CLICK_PROFILE = 'click_profile';
export const TAXONOMY_EVENT_TYPE_CLICK_SIGNUP = 'click_signup';

export const TAXONOMY_EVENT_TYPE_CLICK_SOCIAL_ICON = 'click_social_icon';
export const TAXONOMY_EVENT_TYPE_COMPLETE_LOGIN = 'complete_login';
export const TAXONOMY_EVENT_TYPE_FAIL_LOGIN = 'fail_login';
export const TAXONOMY_EVENT_TYPE_VISIT_BILLING_PLANS = 'visit_billing&plans';
export const TAXONOMY_EVENT_TYPE_VISIT_MY_ACCOUNT = 'visit_my_account';
export const TAXONOMY_EVENT_TYPE_CLICK_DOWNLOAD = 'click_download';
export const TAXONOMY_EVENT_TYPE_COMPLETE_DOWNLOAD = 'complete_download';
export const TAXONOMY_EVENT_TYPE_FAIL_DOWNLOAD = 'fail_download';

export const TAXONOMY_EVENT_TYPE_COMPLETE_SIGN_OUT = 'complete_sign_out';
export const TAXONOMY_EVENT_TYPE_CLICK_ADD_TEXT = 'click_add_text';
export const TAXONOMY_EVENT_TYPE_CLICK_CLOSE_EXPORT = 'click_close_export';
export const TAXONOMY_EVENT_TYPE_CLICK_FLIP = 'click_flip';
export const TAXONOMY_EVENT_TYPE_CLICK_SYMBOL_PANEL = 'click_symbol_panel';
export const TAXONOMY_EVENT_TYPE_CLICK_TEMPLATE_PANEL = 'click_template_panel';
export const TAXONOMY_EVENT_TYPE_CLICK_TEXT_LEADING = 'click_text_leading';
export const TAXONOMY_EVENT_TYPE_CREATE_AUTO_SAVE = 'create_auto_save';
export const TAXONOMY_EVENT_TYPE_CREATE_CLICK_SAVE = 'create_click_save';
export const TAXONOMY_EVENT_TYPE_CREATE_EXIT_AUTO_SAVE = 'create_exit_auto_save';
export const TAXONOMY_EVENT_TYPE_FAIL_SAVE = 'fail_save';
export const TAXONOMY_EVENT_TYPE_REDO = 'redo';
export const TAXONOMY_EVENT_TYPE_SCROLL_FONT = 'scroll_font';
export const TAXONOMY_EVENT_TYPE_SELECT_FONT = 'select_font';
export const TAXONOMY_EVENT_TYPE_SELECT_SYMBOL = 'select_symbol';
export const TAXONOMY_EVENT_TYPE_SELECT_SYMBOL_CATEGORY = 'select_symbol_category';
export const TAXONOMY_EVENT_TYPE_TOOLBAR_BG_COLOR = 'toolbar_bg_color';
export const TAXONOMY_EVENT_TYPE_TOOLBAR_SYMBOL_COLOR = 'toolbar_symbol_color';
export const TAXONOMY_EVENT_TYPE_TOOLBAR_TEXT_COLOR = 'toolbar_text_color';
export const TAXONOMY_EVENT_TYPE_TOOLBAR_TEXT_FONT = 'toolbar_text_font';
export const TAXONOMY_EVENT_TYPE_TEXT_OPACITY_APPLIED = 'text_opacity_applied';
export const TAXONOMY_EVENT_TYPE_SYMBOL_OPACITY_APPLIED = 'symbol_opacity_applied';
export const TAXONOMY_EVENT_TYPE_TOOLBAR_TEXT_SPACING = 'toolbar_text_spacing';
export const TAXONOMY_EVENT_TYPE_UNDO = 'undo';
export const TAXONOMY_EVENT_TYPE_VISIT_EDITOR = 'visit_editor';
export const TAXONOMY_EVENT_TYPE_CLICK_GNB_BLOG = 'click_gnb_blog';
export const TAXONOMY_EVENT_TYPE_CLICK_GNB_PRICING = 'click_gnb_pricing';
export const TAXONOMY_EVENT_TYPE_CLICK_GNB_TEMPLATES = 'click_gnb_templates';
export const TAXONOMY_EVENT_TYPE_CLICK_GNB_LOGO_IDEAS = 'click_gnb_logo_ideas';
export const TAXONOMY_EVENT_TYPE_CLICK_CTA_MAIN_HOME = 'click_cta_main_home';
export const TAXONOMY_EVENT_TYPE_CLICK_DISCOVER_MORE_LOGOS = 'click_discover_more_logos';
export const TAXONOMY_EVENT_TYPE_VISIT_MAIN_HOME = 'visit_main_home';
export const TAXONOMY_EVENT_TYPE_CLICK_CREATE_NEW = 'click_create_new';
export const TAXONOMY_EVENT_TYPE_SELECT_MYWORK_COPY = 'select_mywork_copy';
export const TAXONOMY_EVENT_TYPE_SELECT_MYWORK_DELETE = 'select_mywork_delete';
export const TAXONOMY_EVENT_TYPE_SELECT_MYWORK_RESTORE = 'select_mywork_restore';
export const TAXONOMY_EVENT_TYPE_SELECT_MYWORK_EDIT = 'select_mywork_edit';
export const TAXONOMY_EVENT_TYPE_VISIT_MYWORK = 'visit_mywork';
export const TAXONOMY_EVENT_TYPE_CLICK_GO_TO_FACEBOOK = 'click_go_to_facebook';
export const TAXONOMY_EVENT_TYPE_CLICK_GO_TO_INSTA = 'click_go_to_insta';
export const TAXONOMY_EVENT_TYPE_CLICK_GO_TO_TWITTER = 'click_go_to_twitter';
export const TAXONOMY_EVENT_TYPE_CLICK_PRIVACY_POLICY = 'click_privacy_policy';
export const TAXONOMY_EVENT_TYPE_CLICK_TERMS_OF_SERVICE = 'click_terms_of_service';
export const TAXONOMY_EVENT_TYPE_VISIT_PRIVACY = 'visit_privacy';
export const TAXONOMY_EVENT_TYPE_VISIT_TERMS = 'visit_terms';
export const TAXONOMY_EVENT_TYPE_CLOSE_PURCHASE = 'close_purchase';
export const TAXONOMY_EVENT_TYPE_COMPLETE_PURCHASE = 'complete_purchase';
export const TAXONOMY_EVENT_TYPE_FAIL_SUBSCRIBE = 'fail_subscribe';
export const TAXONOMY_EVENT_TYPE_VIEW_PURCHASE_MODAL = 'view_purchase_modal';
export const TAXONOMY_EVENT_TYPE_CLICK_CTA_PRICING = 'click_cta_pricing';
export const TAXONOMY_EVENT_TYPE_VISIT_PRICING = 'visit_pricing';
export const TAXONOMY_EVENT_TYPE_SELECT_INDUSTRY_CATEGORY = 'select_industry_category';
export const TAXONOMY_EVENT_TYPE_SELECT_TEMPLATE = 'select_template';
export const TAXONOMY_EVENT_TYPE_VISIT_TEMPLATES = 'visit_templates';
export const TAXONOMY_EVENT_TYPE_VIEW_DOWNLOAD_PAYWALL = 'view_download_paywall';
export const TAXONOMY_EVENT_TYPE_CLICK_MOCKUP_ICON = 'click_mockup_icon';
export const TAXONOMY_EVENT_TYPE_CLICK_SHARE = 'click_share';
export const TAXONOMY_EVENT_TYPE_CLICK_SHARE_LINK = 'click_share_link';
export const TAXONOMY_EVENT_TYPE_CLICK_SHARE_VISIT = 'click_share_visit';
export const TAXONOMY_EVENT_TYPE_SELECT_EDIT = 'select_edit';

export const TAXONOMY_EVENT_TYPE_CLICK_SEARCH_INDUSTRY = 'click_search_industry';
export const TAXONOMY_EVENT_TYPE_CLICK_SEARCH_INDUSTRY_RESULT = 'click_search_industry_result';
export const TAXONOMY_EVENT_TYPE_CLICK_CTA_LOGO_IDEAS = 'click_cta_logo_ideas';

export const TAXONOMY_SOCIAL_APPLE = 'apple';
export const TAXONOMY_SOCIAL_FACEBOOK = 'facebook';
export const TAXONOMY_SOCIAL_GOOGLE = 'google';

export const TAXONOMY_SAVE_TYPE_DOWNLOAD = 'download';
export const TAXONOMY_SAVE_TYPE_4M = '4m';
export const TAXONOMY_SAVE_TYPE_SHARE = 'share';

export const TAXONOMY_DOWNLOAD_FORMAT_ZIP = 'zip';
export const TAXONOMY_DOWNLOAD_FORMAT_JPEG = 'jpeg';
export const TAXONOMY_DOWNLOAD_FORMAT_PNG = 'png';
export const TAXONOMY_DOWNLOAD_FORMAT_PDF = 'pdf';

export const TAXONOMY_DOWNLOAD_SIZE_HIGH = 'high';
export const TAXONOMY_DOWNLOAD_SIZE_NORMAL = 'normal';
export const TAXONOMY_DOWNLOAD_SIZE_ALL = 'all';

export const TAXONOMY_DOWNLOAD_LOCATION_MYWORK = 'mywork';
export const TAXONOMY_DOWNLOAD_LOCATION_EDITOR = 'editor';
export const TAXONOMY_DOWNLOAD_LOCATION_MOCKUP = 'editor_mockup';

export const TAXONOMY_SHARE_LOCATION_EDITOR_HEADER = 'editor_header';
export const TAXONOMY_SHARE_LOCATION_EDITOR_MOCKUP_FOOTER = 'editor_mockup';

export const TAXONOMY_SHARE_LINK_LOCATION_EDITOR_POPUP = 'editor_popup';
export const TAXONOMY_SHARE_LINK_LOCATION_MOCKUP_PAGE = 'mockup_page';

export const TAXONOMY_SHARE_LINK_CHANNEL_FACEBOOK = 'facebook';
export const TAXONOMY_SHARE_LINK_CHANNEL_X = 'x';
export const TAXONOMY_SHARE_LINK_CHANNEL_LINKEDIN = 'linkedin';
export const TAXONOMY_SHARE_LINK_CHANNEL_REDDIT = 'reddit';
export const TAXONOMY_SHARE_LINK_CHANNEL_COPY = 'copy';

export const TAXONOMY_OBJECT_TYPE_TEXT = 'text';
export const TAXONOMY_OBJECT_TYPE_SYMBOL = 'symbol';
export const TAXONOMY_OBJECT_TYPE_BACKGROUND = 'background';

export const TAXONOMY_COLOR_TOOL = 'color';
export const TAXONOMY_COLORPICKER = 'colorpicker';

export const TAXONOMY_TEXT_SPACING_LETTER = 'letter';
export const TAXONOMY_TEXT_SPACING_LINE = 'line';

export const TAXONOMY_MAIN_HOME_CTA_EXPLORE_LOGOS = 'explore_logos';
export const TAXONOMY_MAIN_HOME_CTA_GET_YOURS_INSTANTLY = 'get_yours_instantly';

export const TAXONOMY_PRICING_CTA_EXPLORE_TEMPLATES = 'explore_templates';
export const TAXONOMY_PRICING_CTA_SELECT_PLANS = 'select_plans';

export const TAXONOMY_INDUSTRY_CATEGORY_TAB_TEMPLATES = 'tab_templates';
export const TAXONOMY_INDUSTRY_CATEGORY_EDITOR = 'editor';
export const TAXONOMY_INDUSTRY_CATEGORY_FULL_MODE_TEMPLATE = 'full_mode_template';
export const TAXONOMY_INDUSTRY_CATEGORY_MAIN_HOME = 'main_home';
export const TAXONOMY_INDUSTRY_CATEGORY_SHARE = 'share';
export const TAXONOMY_INDUSTRY_CATEGORY_LOGO_IDEAS = 'logo_ideas';
export const TAXONOMY_INDUSTRY_CATEGORY_LOGO_IDEAS_BOTTOM = 'logo_ideas_bottom';

export const TAXONOMY_PURCHASE_POPUP_LOCATION_PRICING_PAGE = 'pricing_page';
export const TAXONOMY_PURCHASE_POPUP_LOCATION_EDITOR = 'editor';
export const TAXONOMY_PURCHASE_STEP_CHECKOUT = 'checkout';
export const TAXONOMY_PURCHASE_STEP_VIEW_PLAN = 'view_plan';

export const TAXONOMY_SELECT_EDIT_LOCATION_MY_WORK = 'my_work';

/**
 * 이벤트 프로퍼티가 특정 상황에서는 빈 데이터를 보내야 하는 상황이라면 'none' 값으로 전송하도록 통일 함.
 * 2024.01.16
 */
export const TAXONOMY_EMPTY_PROPERTY = 'none';
