import { Template, TemplateBackgroundGradient, TemplateObject, TemplateStickerObject, TemplateTextObject } from '@lws/types';
import _ from 'lodash';
import uuid from 'react-uuid';

import { TEXT_OBJECT_PLAIN_TYPE, TEXT_OBJECT_TYPE } from '../../constants';
import { CurvedTextManager, PlainTextManager } from '../../helpers';
import { StickerResourceManager } from '../../helpers/StickerResourceManager';
import { getCurvedTextAngle, getCurvedTextAreaSize, getFontResourceFromDecorators, getImageMaxSize, getStickerObjectResourceId, getStickerSymbolSize, getTextObjectFontSize, getTextObjectLineSpacingCSS, getTextObjectSingleLineHeight, getTextObjectSize, getTextType, setFont } from '..';
import { FontsInitializerArguments, SetInitialStickerObjectSizeArguments, StickerObjectInitializerArguments, TemplateInitializerArguments } from './types';

export const parseTemplate = (originalTemplate: string): Template => {
  try {
    let template = JSON.parse(originalTemplate) as Template;

    template = {
      ...template,
      background: parseBackground(template.background as string),
    };

    return template;
  } catch (e) {
    throw new Error('Cannot parse template data.');
  }
};

export const parseBackground = (background: string): TemplateBackgroundGradient | string => {
  try {
    const parsedBackground = JSON.parse(background);

    return parsedBackground;
  } catch {
    return background;
  }
};

export const getInitialStickerObject = () => {
  const defaultObject = {
    type: 'CLStickerView',
    centerX: 0.5,
    centerY: 0.5,
    hexColor: '#none',
    alpha: 1,
    maskImageCenterX: 0,
    maskImageCenterY: 0,
    maskImageScale: 0,
    arg: 0,
    hue: 0,
    scale: 1,
    brightness: 0,
    decoratorIds: [],
    parameters: {
      primaryKey: uuid(),
    },
  } as Partial<TemplateStickerObject>;

  return defaultObject;
};

export const getStickerWithSize = ({
  objects,
  imageMaxSize,
  canvasWidth,
  canvasHeight,
  deviceScale,
  resourceMeta,
}: SetInitialStickerObjectSizeArguments) => {
  let cloned = _.cloneDeep(objects);

  cloned = cloned.map((object) => {
    const {
      scale,
      type,
    } = object;

    if (type !== 'CLStickerView') return object;

    const resourceId = getStickerObjectResourceId(object.contentId);

    if (!resourceId) throw new Error('There is no Resource ID');
    if (!resourceMeta[resourceId]) throw new Error('Can not find resource meta data.');

    const { width: imageWidth, height: imageHeight } = resourceMeta[resourceId];

    const {
      frameWidth,
      frameHeight,
    } = getStickerSymbolSize({
      canvasWidth,
      canvasHeight,
      imageMaxSize,
      scale,
      deviceScale,
      imageWidth,
      imageHeight,
    });

    return {
      ...object,
      frameWidth,
      frameHeight,
    };
  });

  return cloned;
};

export const getInitializedPlainTextObject = (object: TemplateTextObject) => {
  let clone = _.cloneDeep(object) as TemplateTextObject;
  const {
    decoratorIds,
    kerning,
    scale,
    lineSpacing,
    text,
  } = clone;

  const letterSpacingStyle = PlainTextManager.getLetterSpacingStyleFromKerning(kerning, scale);
  const fontFamily = getFontResourceFromDecorators(decoratorIds);
  const fontSize = getTextObjectFontSize(scale);

  if (fontFamily === undefined) return object;

  const singleLineHeight = getTextObjectSingleLineHeight({
    fontFamily,
    letterSpacing: letterSpacingStyle,
  });

  const lineSpacingStyle = getTextObjectLineSpacingCSS({ singleLineHeight, lineSpacing });

  const {
    width: frameWidth,
    height: frameHeight,
  } = getTextObjectSize({
    text,
    fontFamily,
    letterSpacing: letterSpacingStyle,
    lineSpacing: lineSpacingStyle,
    fontSize,
  });

  clone.parameters = {
    ...clone.parameters,
    singleLineHeight,
  };

  clone = {
    ...clone,
    frameWidth,
    frameHeight,
    parameters: {
      ...clone.parameters,
      singleLineHeight,
    },
  };

  return clone;
};

export const getInitializedCurvedTextObject = (object: TemplateTextObject) => {
  let clone = _.cloneDeep(object) as TemplateTextObject;

  const {
    decoratorIds,
    kerning,
    scale,
    text,
    radius,
  } = clone;

  const fontFamily = getFontResourceFromDecorators(decoratorIds);
  const fontSize = getTextObjectFontSize(scale);

  if (fontFamily === undefined) return clone;

  const letterSpacingStyle = PlainTextManager.getLetterSpacingStyleFromKerning(kerning, scale);

  const arcLength = CurvedTextManager.getCurvedTextArcLength({
    text,
    fontSize,
    fontFamily,
    letterSpacing: letterSpacingStyle,
  });
  const angle = getCurvedTextAngle({ radius, arcLength });

  const singleLineHeight = getTextObjectSingleLineHeight({
    fontFamily,
    letterSpacing: letterSpacingStyle,
  });

  const {
    width: textWidth,
    height: textHeight,
  } = getCurvedTextAreaSize({ radius, angle, singleLineHeight });

  clone = {
    ...clone,
    frameWidth: textWidth,
    frameHeight: textHeight,
    parameters: {
      ...clone.parameters,
      singleLineHeight,
    },
  };

  return clone;
};

export const getInitializedTextObjects = (objects: TemplateObject[]) => {
  return objects.map((object) => {
    const { type } = object;

    if (type !== TEXT_OBJECT_TYPE) return object;

    const textType = getTextType(object as TemplateTextObject);

    if (textType === TEXT_OBJECT_PLAIN_TYPE) {
      return getInitializedPlainTextObject(object as TemplateTextObject);
    }

    return getInitializedCurvedTextObject(object as TemplateTextObject);
  });
};

export const getObjectsWithPrimaryId = (objects: TemplateObject[]) => {
  let cloned = _.cloneDeep(objects);
  cloned = cloned.map((item) => {
    const primaryKey = uuid();

    return {
      ...item,
      parameters: {
        ...item.parameters,
        primaryKey,
      },
    };
  });

  return cloned;
};

export const getStickerObjectSize = (
  imageWidth: number,
  imageHeight: number,
  canvasWidth: number,
  canvasHeight: number,
  deviceScale: number
) => {
  const canvasStandardSize = Math.min(canvasWidth, canvasHeight);
  const imageMaxSize = getImageMaxSize(canvasWidth, canvasHeight);
  const symbolStandardSize = Math.round(imageMaxSize * deviceScale) + 36;
  const scale = (canvasStandardSize * 0.5) / symbolStandardSize;
  const frameSize = imageMaxSize * scale * deviceScale;

  const frameWidth = imageWidth > imageHeight ? frameSize : frameSize * (imageWidth / imageHeight);
  const frameHeight = imageHeight > imageWidth ? frameSize : frameSize * (imageHeight / imageWidth);

  return {
    frameWidth,
    frameHeight,
    scale,
  };
};

export const initializeTemplate = async ({
  template,
  resources,
  meta,
}: TemplateInitializerArguments) => {
  const parsedTemplate = parseTemplate(template);

  await fontsInitializer({ fontResources: resources.font });

  const {
    objects,
    imageMaxSize,
    canvasWidth,
    canvasHeight,
    deviceScale,
  } = parsedTemplate;

  let clonedObjects = _.cloneDeep(objects);

  clonedObjects = getStickerWithSize({
    objects: clonedObjects,
    imageMaxSize,
    canvasWidth,
    canvasHeight,
    deviceScale,
    resourceMeta: meta.symbol,
  });

  clonedObjects = getInitializedTextObjects(clonedObjects);
  clonedObjects = getObjectsWithPrimaryId(clonedObjects);

  return {
    template: parsedTemplate,
    objects: clonedObjects,
  };
};

export const fontsInitializer = async ({
  fontResources,
}: FontsInitializerArguments) => {
  const fontLoaders =
    Object
      .entries(fontResources)
      .map(([fontName, fontData]) => new Promise((resolve, reject) => {
        return setFont({ fontName, fontData })
          .then((result) => {
            resolve(result);
          })
          .catch(() => {
            reject(`Could not import font: ${fontName}`);
          });
      }));

  return (await Promise.all(fontLoaders));
};

export const initializeStickerObject = async ({
  resourceId,
  resourceDestination,
  canvasWidth,
  canvasHeight,
  deviceScale,
}: StickerObjectInitializerArguments) => {
  const {
    width: resourceWidth,
    height: resourceHeight,
  } = await StickerResourceManager.getSize(resourceDestination);

  const object = getInitialStickerObject();
  const {
    frameWidth,
    frameHeight,
    scale,
  } = getStickerObjectSize(
    resourceWidth,
    resourceHeight,
    canvasWidth,
    canvasHeight,
    deviceScale
  );

  object.frameWidth = frameWidth;
  object.frameHeight = frameHeight;
  object.scale = scale;
  object.contentId = `pixo-app://resource@embedded/symbol/${resourceId}`;

  return object;
};
