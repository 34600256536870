import { useEffect, useRef } from 'react';

import { UseIntersectionObserverEventArguments } from './useIntersectionObserverEvent.types';

export const useDetectElementInViewport = ({
  items,
  element,
  callback,
}: UseIntersectionObserverEventArguments) => {
  const isVisible = useRef(false);

  /**
   * 아이템을 불러 왔음에도 Loader가 안보이는 영역까지 내려가지 않아서 이후 callback이 무시 되는 현상을 대응합니다.
   */
  useEffect(() => {
    if (isVisible.current) {
      callback?.();
    }
  }, [
    items,
  ]);

  useEffect(() => {
    if (!element) return;

    const intersection = new IntersectionObserver((entries) => {
      entries.forEach(({ isIntersecting }) => {
        if (isIntersecting) {
          isVisible.current = true;
          callback?.();
        } else {
          isVisible.current = false;
        }
      });
    });

    intersection.observe(element);

    return () => {
      intersection.disconnect();
    };
  }, [element, callback]);
};
