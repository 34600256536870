import { AxiosRequestConfig } from 'axios';

import { GetFontListItemsParams, GetFontListItemsResult, GetResourceCategoryItemParams, GetResourceParams, GetResourcesParams, LGSResource, LGSResourceCategory } from '../../types/lgs-resource';
import { APIClient } from '../APIClient';
import { LGSResourceList } from './ResourcesAPI.types';

export class ResourcesAPI extends APIClient {
  getCategory(categoryId: string, config?: AxiosRequestConfig<GetResourceCategoryItemParams>) {
    return this.axios.get<LGSResourceCategory>(
      `/${this.appName}/resources/categories/${categoryId}`,
      config
    );
  }

  getCategories(categoryIds: string[], config?: AxiosRequestConfig<GetResourceCategoryItemParams>) {
    return Promise.all(categoryIds.map((categoryId) => {
      return this.getCategory(categoryId, config).then((response) => response.data);
    }));
  }

  getResources(categoryId: string, params?: GetResourcesParams) {
    const _params: GetResourcesParams = {
      offset: 0,
      limit: 24,
      ...params,
    };

    return this.axios.get<LGSResourceList>(
      `/${this.appName}/resources/categories/${categoryId}/resources/list`,
      { params: _params }
    );
  }

  getResource(resourceId: string, params?: GetResourceParams) {
    const newParams = { ...params };

    return this.axios.get<LGSResource>(
      `/${this.appName}/resources/${resourceId}`,
      { params: newParams }
    );
  }

  getFontListItems(
    params?: GetFontListItemsParams,
    fontCategoryId: string = process.env.NEXT_PUBLIC_FONTS_CATEGORY_ID as string
  ): Promise<GetFontListItemsResult> {
    const newParams = {
      select: 'id,sourceData,displayName,previewFontSourceUrl',
      ...params,
    };

    return new Promise((resolve, reject) => {
      this.axios.get<GetFontListItemsResult>(
        `/${this.appName}/resources/categories/${fontCategoryId}/resources/list`,
        { params: newParams }
      ).then((result) => {
        resolve(result.data);
      }).catch(() => {
        reject();
      });
    });
  }
}

export const resourceAPIClient = new ResourcesAPI();

export default resourceAPIClient;
